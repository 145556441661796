import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import { navItems } from './navbar';
import {AuthenticationService} from '../../services/authentication.service';
import {NavbarService} from '../../services/navbar.service';
import {LoadingService} from '../../services/loading.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss']
})
export class PanelComponent implements OnInit, OnDestroy {

  public navItems = navItems;
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement;

  constructor(
    @Inject(DOCUMENT) _document?: any,
    public authenticationService?: AuthenticationService,
    public navbarService?: NavbarService,
    public loading?: LoadingService,
  ) {
    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = _document.body.classList.contains('sidebar-minimized');
    });
    this.element = _document.body;
    this.changes.observe(this.element as Element, {
      attributes: true,
      attributeFilter: ['class']
    });
  }

  ngOnInit() {
    // this.navbarService.mountNavbar(this.authenticationService.currentAuthUserValue.user);
    this.authenticationService.refreshUser().subscribe(
      data => {
        this.navItems = this.navbarService.navItems;
      });
  }

  ngOnDestroy(): void {
    this.changes.disconnect();
  }

  logout() {
    // reset login status
    this.authenticationService.logout();
  }

}
