import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SearchFieldComponent} from '../../components/panel/search-field/search-field.component';
import {SortColumnComponent} from '../../components/panel/sort-column/sort-column.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxLoadingModule} from 'ngx-loading';
import {EditorModule} from '@tinymce/tinymce-angular';
import {NgxSmartModalModule} from 'ngx-smart-modal';
import {SafePipe} from '../../pipes/safe.pipe';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {NgxCurrencyModule} from 'ngx-currency';
import {NgSelectModule} from '@ng-select/ng-select';

@NgModule({
  declarations: [
    SearchFieldComponent,
    SortColumnComponent,
    SafePipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PaginationModule,
    NgxLoadingModule,
    NgxSmartModalModule,
    NgxCurrencyModule,
    NgSelectModule,
  ],
  exports: [
    SafePipe,
    CommonModule,
    EditorModule,
    FormsModule,
    ReactiveFormsModule,
    PaginationModule,
    NgxLoadingModule,
    NgxCurrencyModule,
    NgSelectModule,
    SearchFieldComponent,
    SortColumnComponent,
  ]
})
export class SharedModule { }
