import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import {environment} from '../../environments/environment';

@Injectable()
export class UrlInterceptor implements HttpInterceptor {
  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let url = request.url;
    if (url.substr(0, 1) !== '/') {
      url = `${environment.apiUrl}/${url}`;
    }
    url = `${environment.host}${url}`;
    request = request.clone({
      url
    });
    return next.handle(request);
  }
}
