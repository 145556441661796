<div class="page">
  <div class="container">
    <div class="row">
      <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
        <div class="card card-signin my-5">
          <div class="card-body">
            <login-form></login-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
