import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {P404Component} from './components/errors/404.component';
import {P500Component} from './components/errors/500.component';
import {AuthGuard} from './guards/auth.guard';
import {PanelComponent} from './components/panel/panel.component';
import {LoginPageComponent} from './components/login/login-page/login-page.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginPageComponent,
    data: {
      title: 'Login'
    }
  },
  {
    path: '',
    component: PanelComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'settings',
        loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule)
      },
      {
        path: 'users',
        loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule),
      },
      {
        path: 'alerts',
        loadChildren: () => import('./modules/alerts/alerts.module').then(m => m.AlertsModule),
      },
      {
        path: 'deadlines',
        loadChildren: () => import('./modules/deadlines/deadlines.module').then(m => m.DeadlinesModule),
      },
      {
        path: 'plans',
        loadChildren: () => import('./modules/plans/plans.module').then(m => m.PlansModule),
      },
      {
        path: 'agencies',
        loadChildren: () => import('./modules/agencies/agencies.module').then(m => m.AgenciesModule),
      },
      {
        path: 'services',
        loadChildren: () => import('./modules/services/services.module').then(m => m.ServicesModule),
      },
      {
        path: 'commerce-committee-taxes',
        loadChildren: () => import('./modules/commerce-committee-taxes/commerce-committee-taxes.module')
          .then(m => m.CommerceCommitteeTaxesModule),
      },
      {
        path: 'payment-methods',
        loadChildren: () => import('./modules/payment-methods/payment-methods.module').then(m => m.PaymentMethodsModule),
      },
      {
        path: 'roles',
        loadChildren: () => import('./modules/roles/roles.module').then(m => m.RolesModule),
      },
      {
        path: 'proposals',
        loadChildren: () => import('./modules/proposals/proposals.module').then(m => m.ProposalsModule),
      },
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
