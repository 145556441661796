import { BrowserModule } from '@angular/platform-browser';
import {DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {LoginFormComponent} from './components/login/login-form/login-form.component';
import {LoginPageComponent} from './components/login/login-page/login-page.component';
import {P404Component} from './components/errors/404.component';
import {P500Component} from './components/errors/500.component';
import {PanelComponent} from './components/panel/panel.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AppAsideModule, AppBreadcrumbModule, AppFooterModule, AppHeaderModule, AppSidebarModule} from '@coreui/angular';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {NgxSmartModalModule} from 'ngx-smart-modal';
import {NgxLoadingModule} from 'ngx-loading';
import {ToastrModule} from 'ngx-toastr';
import {SharedModule} from './modules/shared/shared.module';
import {UrlInterceptor} from './interceptors/url.interceptor';
import {ApiInterceptor} from './interceptors/api.interceptor';
import {AuthInterceptor} from './interceptors/auth.interceptor';
import {ErrorInterceptor} from './interceptors/error.interceptor';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxMaskModule} from 'ngx-mask';
import {NGX_BOOTSTRAP_ALERT_DEFAULT_OPTIONS, NGX_BOOTSTRAP_CONFIRM_DEFAULT_OPTIONS} from 'ngx-bootstrap-dialog';
import {registerLocaleData} from '@angular/common';

import localePt from '@angular/common/locales/pt';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {LocalDataService} from './services/local-data.service';
registerLocaleData(localePt, 'pt');

@NgModule({
  declarations: [
    AppComponent,
    LoginFormComponent,
    LoginPageComponent,
    P404Component,
    P500Component,
    PanelComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    AppRoutingModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    PaginationModule.forRoot(),
    AppBreadcrumbModule.forRoot(),
    AppAsideModule,
    AppFooterModule,
    TabsModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    NgxSmartModalModule.forRoot(),
    ToastrModule.forRoot(),
    NgxMaskModule.forRoot(),
    SharedModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: UrlInterceptor, multi: true},
    {
      provide: NGX_BOOTSTRAP_ALERT_DEFAULT_OPTIONS,
      useValue: {
        confirmButtonClass: 'btn btn-primary',
        confirmButtonLabel: 'Estou ciente',
      },
    },
    {
      provide: NGX_BOOTSTRAP_CONFIRM_DEFAULT_OPTIONS,
      useValue: {
        confirmButtonClass: 'btn btn-danger',
        confirmButtonLabel: 'Continuar',
        cancelButtonLabel: 'Cancelar',
      },
    },
    {
      provide: LOCALE_ID,
      useValue: 'pt'
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'BRL'
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
