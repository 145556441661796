import { Component, OnInit } from '@angular/core';
import { LoginFormComponent } from '../login-form/login-form.component';

@Component({
  selector: 'login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {
  declarations: [
    LoginFormComponent
  ]
  constructor() { }

  ngOnInit() {
  }

}
