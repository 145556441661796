<form class="form-signin" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
  <div class="brand">
    <img class="mb-4 img-fluid" src="/assets/img/brand/logo.svg">
  </div>
  <h1 class="h3 mb-3 font-weight-normal">Identifique-se</h1>

  <div class="input-group input-group-email">
    <div class="input-group-prepend">
      <div class="input-group-text">@</div>
    </div>
    <input type="email" id="inputEmail" formControlName="email" class="form-control" placeholder="Email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" autofocus>
  </div>
  <div class="input-group mb-4 input-group-password">
    <div class="input-group-prepend">
      <div class="input-group-text"><i class="fas fa-key"></i></div>
    </div>
    <input type="password" id="inputPassword" formControlName="password" class="form-control" placeholder="Senha" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
  </div>

  <!--div class="custom-control custom-checkbox mb-3">
    <input type="checkbox" class="custom-control-input" id="customCheck1">
    <label class="custom-control-label" for="customCheck1">Mantenha-me conectado</label>
  </div-->
  <button [disabled]="loading" class="btn btn-lg btn-primary btn-block" type="submit">Entrar</button>
  <img *ngIf="loading" class="pl-2" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
  <hr class="my-4">
  <!--button class="btn btn-lg btn-google btn-block" type="submit">
    <i class="fab fa-google mr-2"></i>
    Entrar com o Google
  </button>
  <button class="btn btn-lg btn-facebook btn-block" type="submit">
    <i class="fab fa-facebook mr-2"></i>
    Entrar com o Facebook
  </button-->
</form>
