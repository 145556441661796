import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {User} from '../modules/users/models/user';
import {first, map, switchMap} from 'rxjs/operators';
import {Auth} from '../models/auth';
import {Router} from '@angular/router';
import {NavbarService} from './navbar.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private currentAuthUserSubject: BehaviorSubject<Auth>;
  public  currentAuthUser: Observable<Auth>;

  constructor(
    private http: HttpClient,
    private router: Router,
    private navbarService: NavbarService,
  ) {
    this.updateCurrentAuthUser();
  }

  public get currentAuthUserValue(): Auth {
    return this.currentAuthUserSubject.value;
  }

  private updateCurrentAuthUser() {
    this.currentAuthUserSubject = new BehaviorSubject<Auth>(JSON.parse(localStorage.getItem('currentAuthUser')));
    this.currentAuthUser = this.currentAuthUserSubject.asObservable();
  }

  login(username: string, password: string) {
    return this.http.post<any>(environment.tokenUrl, {
      username,
      password,
      grant_type: 'password',
      client_id: environment.oauthClientId,
      client_secret: environment.oauthClientSecret
    })
      .pipe(
        first(),
        switchMap(auth => {
          if (auth && auth.access_token) {
            localStorage.setItem('currentAuthUser', JSON.stringify(auth));
            this.updateCurrentAuthUser();
            return this.refreshUser();
          }
        })
      );
  }

  refreshUser(): Observable<any> {
    const auth = this.currentAuthUserValue;
    return this.http.get<any>('user')
      .pipe(
        first(),
        map(user => {
          if (user) {
            auth.user = user;
            localStorage.setItem('currentAuthUser', JSON.stringify(auth));
            this.updateCurrentAuthUser();
            this.currentAuthUserSubject.next(auth);
            this.navbarService.mountNavbar(this.currentAuthUserValue.user);
          }
          return auth;
        })
      );
  }

  logout() {
    localStorage.removeItem('currentAuthUser');
    this.currentAuthUserSubject.next(null);

    this.router.navigateByUrl(`login`);
  }
}
