<app-header
  [navbarBrandRouterLink]="['/dashboard']"
  [fixed]="true"
  [navbarBrandFull]="{src: 'assets/img/brand/logo.svg', width: 140, alt: 'Logo'}"
  [navbarBrandMinimized]=""
  [sidebarToggler]="'lg'"
  [asideMenuToggler]="false"
  [mobileAsideMenuToggler]="false"
>
  <ul class="nav navbar-nav ml-auto">
    <li class="nav-item px-3">
      {{this.authenticationService.currentAuthUserValue.user.name}}
    </li>
    <li class="nav-item px-3">
      <a class="nav-link" title="Sair" [routerLink]="" (click)="logout()"><i class="fas fa-sign-out-alt fa-2x"></i></a>
    </li>
  </ul>
</app-header>
<div class="app-body">
  <app-sidebar [fixed]="true" [display]="'lg'">
    <app-sidebar-nav [navItems]="navItems" [perfectScrollbar] [disabled]="sidebarMinimized"></app-sidebar-nav>
    <app-sidebar-minimizer></app-sidebar-minimizer>
  </app-sidebar>
  <!-- Main content -->
  <main class="main position-relative">
    <!-- Breadcrumb -->
    <!-- breaking change 'cui-breadcrumb' -->
    <cui-breadcrumb></cui-breadcrumb>
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div><!-- /.container-fluid -->
  </main>
</div>

<ngx-loading [show]="loading.show" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
