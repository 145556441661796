import { Injectable } from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import { catchError } from 'rxjs/operators';

import {ToastrService} from 'ngx-toastr';
import {AuthenticationService} from '../services/authentication.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService, private toastr: ToastrService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError((error: HttpErrorResponse) => {
      let unknownError = true;

      const toastrOptions = {
        closeButton: true,
        enableHtml: true
      };

      if ([401].includes(error.status)) {
        // auto logout if 401 response returned from api
        this.authenticationService.logout();
        location.reload(true);
        unknownError = false;
      }

      if ([422].includes(error.status)) {
        const errors = Object.values(error.error.errors);
        errors.map((e: Array<string>) => this.toastr.error('- ' + e.join('<br>'), 'Dados inválidos', toastrOptions));
        unknownError = false;
      }

      if ([500].includes(error.status)) {
        if (error.error) {
          this.toastr.error(error.error.message, 'Erro interno no servidor.', toastrOptions);
        }else{
          this.toastr.error('Erro não especificado.', 'Erro interno no servidor.', toastrOptions);
        }
        unknownError = false;
      }

      if (unknownError) {
        this.toastr.error('Erro desconhecido.', 'Erro', toastrOptions);
      }

      return throwError(error);
      /*return of(new HttpResponse({
        body: error.error
      }));*/
    }));
  }
}
