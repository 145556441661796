import { Injectable } from '@angular/core';
import { navItems } from '../components/panel/navbar';
import {User} from '../modules/users/models/user';
import {PermissionService} from './permission.service';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {
  public navItems = navItems;
  private user: User = null;

  constructor(
    private permissionService: PermissionService
  ) {}

  public mountNavbar(user: User): void {
    this.user = user;

    this.navItems = [];

    this.navItems.push({
      name: 'Painel',
      url: '/',
      icon: 'fas fa-tachometer-alt',
    });
    if (this.permissionService.hasPermission(this.user, 'proposals')) {
      this.navItems.push({
        name: 'Propostas',
        icon: 'fas fa-money-check-alt',
        url: '/proposals',
      });
    }
    if (this.permissionService.hasPermission(this.user, 'users')) {
      this.navItems.push({
        name: 'Usuários',
        icon: 'fas fa-users',
        url: '/users',
      });
    }
    if (this.permissionService.hasPermission(this.user, 'plans')) {
      this.navItems.push({
        name: 'Planos',
        icon: 'fas fa-file-invoice-dollar',
        url: '/plans',
      });
    }
    if (this.permissionService.hasPermission(this.user, 'services')) {
      this.navItems.push({
        name: 'Serviços',
        icon: 'fab fa-stack-exchange',
        url: '/services',
      });
    }
    if (this.permissionService.hasPermission(this.user, 'commerce-committee-taxes')) {
      this.navItems.push({
        name: 'Taxas',
        icon: 'fas fa-hand-holding-usd',
        url: '/commerce-committee-taxes',
      });
    }
    if (this.permissionService.hasPermission(this.user, 'payment-methods')) {
      this.navItems.push({
        name: 'M. Pagamento',
        icon: 'fas fa-money-check-alt',
        url: '/payment-methods',
      });
    }
    if (this.permissionService.hasPermission(this.user, 'roles')) {
      this.navItems.push({
        name: 'Funções',
        icon: 'fas fa-user-tag',
        url: '/roles',
      });
    }
    if (this.permissionService.hasPermission(this.user, 'roles')) {
      this.navItems.push({
        name: 'Alertas',
        icon: 'fas fa-bell',
        url: '/alerts',
      });
    }
    if (this.permissionService.hasPermission(this.user, 'roles')) {
      this.navItems.push({
        name: 'Prazos',
        icon: 'fas fa-calendar-day',
        url: '/deadlines',
      });
    }
    if (this.permissionService.hasPermission(this.user, 'agencies')) {
      this.navItems.push({
        name: 'Órgãos',
        icon: 'fas fa-building',
        url: '/agencies',
      });
    }
    if (this.permissionService.hasPermission(this.user, 'settings')) {
      this.navItems.push({
        name: 'Configurações',
        icon: 'fas fa-cog',
        url: '/settings',
      });
    }
  }
}
