<div class="app flex-row align-items-center">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="clearfix">
          <h1 class="float-left display-3 mr-4">404</h1>
          <h4 class="pt-3">Oops! Você está perdido.</h4>
          <p class="text-muted">A página que você procura não foi encontrada.</p>
        </div>
        <div class="text-center">
          <hr>
          <a routerLink="/" class="btn btn-info">Ir para o painel</a>
        </div>
      </div>
    </div>
  </div>
</div>
