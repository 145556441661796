import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: '[sortColumn]',
  template: `
    <a href="javascript:void(0)"><ng-content></ng-content></a>&nbsp;
    <i class="fa" [ngClass]="{'fa-caret-down': showArrowDown(), 'fa-caret-up': showArrowUp()}"></i>
  `,
  styles: []
})
export class SortColumnComponent implements OnInit {

  @Input()
  sortColumn: {sort, order};

  @Input()
  columnName;

  @Output()
  onSort = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  @HostListener('click')
  changeSort() {
    this.sortColumn.sort = this.columnName;
    this.sortColumn.order = this.sortColumn.order === 'desc' ? 'asc' : 'desc';
    this.onSort.emit(this.sortColumn);
  }

  showArrowDown() {
    return this.columnName === this.sortColumn.sort && this.sortColumn.order === 'desc';
  }

  showArrowUp() {
    return this.columnName === this.sortColumn.sort && this.sortColumn.order === 'asc';
  }

}
