export const environment = {
  production: false,
  title: 'Gerenciador de Propostas',
  host: 'https://staging.server.gerpro.contaja.com.br',
  tokenUrl: '/oauth/token',
  refreshTokenUrl: '/oauth/token/refresh',
  apiUrl: '/api',
  oauthClientId: '95711865-098c-4ff8-a5a3-514e44bd6b0b',
  oauthClientSecret: 'yZMiCM4hvIXkbikq7YKGnA43WOXVT1ZhoDmrpamN',
  pageLimit: 30,
};

