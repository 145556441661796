import { Injectable } from '@angular/core';
import {User} from '../modules/users/models/user';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor() {}

  public hasPermission(user: User, slug: string): boolean {
    return !!user.permissions.find((permission) => permission.slug === slug);
  }
}
