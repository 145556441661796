import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  public show = false;

  public items: {
    id: number,
    text: string
  }[] = [];
  constructor() { }

  public start(text: string): number{
    const id = Date.now() + Math.random();
    this.items.push({
      id,
      text
    });
    this.setShow();
    return id;
  }

  public stop(id: number): void{
    this.items = [];
    this.setShow();
  }

  setShow(): void{
    // this.show = this.items.length > 0;
  }
}
