import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'search-field',
  template: `
    <form (submit)="submit()">
        <div class="input-group">
            <input class="form-control border-info" type="search" name="search"
                   placeholder="Digite sua busca" aria-label="Digite sua busca"
                   [(ngModel)]="search">
            <div class="input-group-append">
                <button class="btn btn-outline-info" type="submit" title="Buscar"><i class="fas fa-search"></i></button>
            </div>
        </div>
    </form>
  `,
  styles: []
})
export class SearchFieldComponent implements OnInit {

  public search = '';

  @Output()
  onSearch: EventEmitter<string> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  submit() {
    this.onSearch.emit(this.search);
    return false;
  }
}
